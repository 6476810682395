<template>
    <div class="product product-style" :key="product.databaseId">
      <div class="product-inner relative">

        <WpImage v-if="madeInIsraelProducts.includes(product?.databaseId)" class-list="made-in-israel-tag" :img="madeInIsraelTag?.productTag?.tagImage?.node" />
        <NuxtLink class="product-image aspect-square gallery-item relative d-block relative"
                  :to="product.uri" >
              <span class="out-of-stock" v-if="outOfStock.includes(product?.databaseId) ">אזל המלאי</span>
          <span ref="productImage" >
          <WpImage :img="product?.image"  :set-webp="true"/>
          </span>
          <transition name="fade-in" v-show="addedToCart">

            <div class="added-icon flex flex-column text-white font-extrabold backdrop-blur">
              <div class="added-icon-check">
                <nuxt-img style="max-width: 100%" src="/images/check-mark.png" alt="אייקון ניבחר"/>
<!--                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>-->
              </div>
            </div>
          </transition>

          <div class="overlay d-flex justify-content-end align-items-center flex-column"></div>
        </NuxtLink>
        <div class="accessory-wrapper">
          <h4 class="woocommerce-loop-product__title">{{ product?.title }}</h4>
          <div class="text-center position-relative mb-md-2" style="" >
            <a
                href="javascript:void(0)"
                class="show-more-bt text-center link-hover"
                @click="$activeModalsBus.event('openUpsaleProductPop', product)"
            >לפרטים נוספים</a
            >
          </div>
          <div class="red-underline red-underline--short2 text-center mb-md-4">
                <span class="price">
                  <span class="woocommerce-Price-amount amount">
                    {{ product?.price }}
                  </span>
                </span>
          </div>
        <div class="px-2">

          <div class="bt_red bt_red--big text-center mx-auto mt-auto">
            <a href="javascrpit:void(0)" @click.prevent="addToCartType" v-if="inStock" rel="nofollow">{{ buttonTitle }}</a>
            <nuxt-link :to="product.uri" v-else rel="nofollow">{{ buttonTitle }}</nuxt-link>
          </div>
        </div>
        </div>
      </div>
  </div>
  <VDialog v-model="showPop" width="800"  scroll-strategy="none">
      <v-btn
          base-color="transparent"
          color="white"
          class="p-0 left-5 top-5 z-20"
          width="45px"
          height="45px"
          min-width="35"
          rounded="circle"

          @click="showPop = false"

      >
        <nuxt-img src="/images/svg/close.svg" width="30" height="30" alt="אייקון של X לסימון סגירה חלון באתר"/>
      </v-btn>
    <VCard>
      <VCardTitle class="text-center  font-bold"><span class="font-bold">{{product.name}}</span></VCardTitle>
      <VDivider/>
      <VCardText class="text-center">

      <div class="product-upsale__colors" v-if="product.productFields?.activeColorSelect">
        <div class="mat-select mat-color-select flex justify-content-md-between mt-2 flex-wrap">
          <div class="col-md-2 col-12 text-right">
            <div class="d-inline d-md-block ml-2" v-if="product.productFields?.titleForVar">{{ product.productFields?.titleForVar }}</div>
            <div class="d-inline d-md-block ml-2" v-else>צבע המגן</div>
            <div class="selected-color font-weight-bold d-inline font-bold d-md-block">
              {{ selectedColor?.label || "צבע הנבחר" }}
            </div>
          </div>
          <div class="col-md col-12">
            <div class="color-radios color-radios__mat_color flex flex-wrap flex-row justify-content-md-end justify-content-center">
              <div
                  class="radio-wrap mat-color"
                  :class="{ active: color.nameEn == selectedColor?.value }"
                  v-for="color in options?.matColors"
              >
                <div
                    :class="`border-round-select mat-color-${color.nameEn}`"
                    :style="`
                      background-image: url('${color?.select?.node?.sourceUrl}');
                    `"
                    @click="colorSelect('color', { label: color.name, value: color.nameEn })"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="mat-select mat-border-select mt-2 flex flex-row justify-content-md-between flex-wrap">
          <div class="col-md-auto col-12 text-right">
            <div class="d-inline d-md-block ml-2">צבע המסגרת</div>
            <div class="selected-color font-bold d-inline d-md-block">
              {{ selectedBorderColor?.label || "צבע הנבחר" }}
            </div>
          </div>
          <div class="col">
            <div class="color-radios color-radios__mat_color flex flex-wrap flex-row justify-content-end">
              <div
                  class="radio-wrap mat-color"
                  :class="{ active: color.nameEn == selectedBorderColor?.value }"
                  v-for="color in options?.matBorderColors"
              >
                <div
                    :class="`border-round-select mat-color-${color.nameEn}`"
                    :style="`
                      background-image: url('${color?.select?.node.sourceUrl}');
                    `"
                    @click="colorSelect('border', { label: color.name, value: color.nameEn })"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-upsale__colors" v-if="product.productFields?.isVarProduct">
        <div class="mat-select mat-color-select flex flex-wrap justify-content-md-between mt-2">
          <div class="col-md-auto" v-if="product.productFields?.titleForVar">
            <div class="d-inline d-md-block ml-2">{{ product.productFields?.titleForVar }}</div>
            <div class="selected-color font-weight-bold d-inline font-bold d-md-block">
              {{ selectedColor?.label || "צבע הנבחר" }}
            </div>
          </div>
          <div class="col-md-auto" v-else>
            <div class="d-inline d-md-block ml-2">צבע המגן</div>
            <div class="selected-color font-weight-bold d-inline font-bold d-md-block">
              {{ selectedColor?.label || "צבע הנבחר" }}
            </div>
          </div>
          <div class="col-md col-12">
            <div class="color-radios color-radios__mat_color flex flex-wrap flex-row justify-content-md-end justify-content-center "
                 :class="{'flex-column align-items-end' : !product?.productFields?.varProduct?.variations[0].select?.node?.sourceUrl }">


              <div
                  class="radio-wrap mat-color mb-2 "
                  :class="{ active: row.nameEn == selectedColor?.value }"
                  v-for="row in product?.productFields?.varProduct?.variations"
              >
                <div v-if="row?.select?.node?.sourceUrl"
                     :class="`border-round-select mat-color-${row.nameEn}`"
                     :style="`background-image: url('${row?.select?.node?.sourceUrl}');`"
                     @click="colorSelect('color', { label: row.name, value: row.nameEn })"
                >
                </div>
                <div v-else
                     :class="`px-2 border-2  cursor-pointer mat-color-${row.nameEn}`"
                     :style="`background-image: url('${row?.select?.node?.sourceUrl}');`"
                     @click="colorSelect('color', { label: row.name, value: row.nameEn })"
                >
                  {{ row.name }}
                </div>
                <!--                  <div-->
                <!--                      :class="`border-round-select mat-color-${color.nameEn}`"-->
                <!--                      :style="`-->
                <!--                      background-image: url('${color?.select?.node?.sourceUrl}');-->
                <!--                    `"-->
                <!--                      @click="colorSelect('color', { label: color.name, value: color.nameEn })"-->
                <!--                  ></div>-->
              </div>
            </div>
          </div>
          <div class="mat-wrap d-flex justify-content-center col-12">
            <span class="mat position-relative text-center">
              <nuxt-img
                  loading="lazy"

                  v-for="(row, index) in productVariations"
                  :key="`${index}-${row.nameEn}`"
                  :class="`mat-color  mat-color-${row?.nameEn} ${row?.nameEn == 'black' ? 'position-relative active' : ''}
                                          ${row?.nameEn == selectedColor?.value ? ' active' : ''}
                                          `"
                  :src="row?.image?.node?.sourceUrl"
                  :alt="row?.image?.node?.altText"
              />
            </span>
          </div>
        </div>
        <template v-if="isKeychain">
          <ProductTemplateKeychain  :product="product" @update-product="keychainUpdate"/>
        </template>
      </div>
      </VCardText>
      <VDivider/>
      <div class="text-center mb-3">

        <VBtn color="red" width="150" @click="addFromPop" :height="40"  type="submit">
הוסף להזמנה
        </VBtn>
      </div>

    </VCard>
  </VDialog>
  <van-popup
      teleport="body"
      v-model:show="showSamples"
      class="material-select-modal"
      id="materialSelectModal"
      :position="width < 800 ? 'left':'center'"
      :style="{'width' : width < 800 ? '80%' : '50%'}"
      closeable
      z-index="99999"
  >
    <div class="modal-content py-5">
      <div class="modal-body px-2">
        <div class="title-h4">משפטים לדוגמא:</div>
        <div class="my-2 text-lg" v-for="text in product?.productFields?.sampleWords">
          {{ text?.text }}
        </div>
      </div>
    </div>
  </van-popup>
</template>
<script setup lang="ts">

const {options} = useGlobalStore();
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});
const {addedItems,cart} = storeToRefs(useCartStore());
const { addToCart,syncCartFromServer } = useCartStore();
const {madeInIsraelProducts,madeInIsraelTag,outOfStock} = useGlobalStore();
const loading = ref(false);
const selectedColor = ref({label: "שחור", value: "black"});
const selectedBorderColor = ref({label: "שחור", value: "black"});
const selectedProduct = ref(false);
const addedToCart = ref(false);
const {$activeModalsBus} = useNuxtApp();
const upsaleItem = ref(null);
const productImage = ref(null);
const showPop = ref(false);
const productVariations = ref(props.product?.productFields?.varProduct.variations);
const isKeychain = ref(props?.product?.productFields?.isKeychain);
const sideA = ref('');
const sideB = ref('');
const sideAText = ref('');
const sideBText = ref('');
const hasSideB = ref(false);
const priceToAdd = ref(0);
const { width, height } = useWindowSize();
const showSamples = ref(false);
const inStock = computed(() => {
  return !outOfStock.includes(props.product?.databaseId);
})

const colorSelect = (type, color) => {
  switch (type) {
    case "color":
      selectedColor.value = color;
      $activeModalsBus.event("addUpsale", {
        price: props.product?.rawPrice,
        data: addInput(props.product),
        product: props.product,
        quantity: 1,
        rawData: addMoreData(props.product)
      });

      break;
    case "border":
      selectedBorderColor.value = color;
      $activeModalsBus.event("addUpsale", {
        price: props.product?.rawPrice,
        data: addInput(props.product),
        product: props.product,
        quantity: 1,
        rawData: addMoreData(props.product)
      });
      break;
  }
};
// watch(addedItems.value, (cart) => {
//   if (_Find(cart, {id: props.product.databaseId})) {
//     selectedProduct.value = false;
//     $activeModalsBus.event("removeUpsale", props.product.databaseId);
//   }
//
// });

function showMore() {
  selectedProduct.value = !selectedProduct.value;

  if (selectedProduct.value) {
    $activeModalsBus.event("addUpsale", {
      price: props.product?.rawPrice,
      data: addInput(props.product),
      product: props.product,
      quantity: 1,
      rawData: addMoreData(props.product)
    });
  } else {
    $activeModalsBus.event("removeUpsale", props.product.databaseId);
  }
}

const productType = computed(() => {
  if (props.product.productFields.activeColorSelect) {
    return "color";
  }
  if (props.product.productFields.isVarProduct) {
    return "var";
  }
  return "default";
});
const buttonTitle = computed(() => {
  if (!inStock.value){
    return "מידע נוסף";
  }
  if (productType.value === "default") {
    return "הוסף לסל";
  }
  return "בחר אפשרויות";
});
const addMoreData = (product) => {
  let type = "default";
  if (product.productFields.activeColorSelect) {
    if (!selectedColor.value) {
      colorSelect("color", {label: options?.matColors[0].name, value: options?.matColors[0].nameEn});
    }
    if (!selectedBorderColor.value) {
      colorSelect("border", {label: options?.matBorderColors[0].name, value: options?.matBorderColors[0].nameEn});
    }
    type = "color";
  }
  if (product.productFields.isVarProduct) {
    type = "var";
    if (!selectedColor.value) {
      colorSelect("color", {
        label: product?.productFields?.varProduct?.variations[0].name,
        value: product?.productFields?.varProduct?.variations[0].nameEn,
      });
    }
  }
  let keychainObject = {};
  if (isKeychain.value){
    let label = product?.productFields?.keychainProduct?.variations?.find((item) => item?.nameEn === sideA.value)?.name;
    let labelB = hasSideB.value ? product?.productFields?.keychainProduct?.variations?.find((item : Object) => item?.nameEn === sideB.value)?.name : '';
    keychainObject = {value : sideAText.value ,label : label, hasSideB : hasSideB.value, sideB : { value: sideBText.value, label : labelB }, priceAdd : priceToAdd.value};
  }
  const productId = product?.databaseId;
  const quantity = 1;
  let extraData = {};
  switch (type) {
    case "color":
      extraData = ({
        is_nuxt: true,
        mat_color: (selectedColor.value),
        mat_border_color: (selectedBorderColor.value),
      });
      break;
    case "var":
      extraData = ({
        is_nuxt: true,
        mat_color: (selectedColor.value),
        keychain: isKeychain.value ? keychainObject: ''

      });
      break;
    default:
      extraData = ({
        is_nuxt: true,
      });
      break;
  }

  return extraData;
};

const addInput = (product) => {
  let type = "default";
  if (product.productFields.activeColorSelect) {
    if (!selectedColor.value) {
      colorSelect("color", {label: options?.matColors[0].name, value: options?.matColors[0].nameEn});
    }
    if (!selectedBorderColor.value) {
      colorSelect("border", {label: options?.matBorderColors[0].name, value: options?.matBorderColors[0].nameEn});
    }
    type = "color";
  }
  if (product.productFields.isVarProduct) {
    type = "var";
    if (!selectedColor.value) {
      colorSelect("color", {
        label: product?.productFields?.varProduct?.variations[0].name,
        value: product?.productFields?.varProduct?.variations[0].nameEn,
      });
    }
  }
  let keychainObject = {};
  if (isKeychain.value){
    let label = product?.productFields?.keychainProduct?.variations?.find((item) => item?.nameEn === sideA.value)?.name;
    let labelB = hasSideB.value ? product?.productFields?.keychainProduct?.variations?.find((item : Object) => item?.nameEn === sideB.value)?.name : '';
    keychainObject = {value : sideAText.value ,label : label, hasSideB : hasSideB.value, sideB : { value: sideBText.value, label : labelB }, priceAdd : priceToAdd.value};

  }
  const productId = product?.databaseId;
  const quantity = 1;
  let extraData = {};
  switch (type) {
    case "color":
      extraData = JSON.stringify({
        is_nuxt: true,
        mat_color: (selectedColor.value),
        mat_border_color: (selectedBorderColor.value),
      });
      break;
    case "var":
      extraData = JSON.stringify({
        is_nuxt: true,
        mat_color: (selectedColor.value),
        keychain: isKeychain.value ? keychainObject: ''
      });
      break;
    default:
      extraData = JSON.stringify({
        is_nuxt: true,
      });
      break;
  }

  return {
    productId,
    quantity,
    extraData,
  };
};
const upsaleAddObject = computed(() => {
  let price = Number(props.product?.rawRegularPrice);
  console.log(price);
  price += priceToAdd.value;
  console.log(price,priceToAdd.value);
  return {
    price: price,
    data: addInput(props.product),
    product: props.product,
    quantity: 1,
    rawData: addMoreData(props.product)
  };
});
const addToCartType = () => {
  if (productType.value === "default") {
    submitAddToCart();
  } else {
    showPop.value = true;
  }
};
const addFromPop = () => {
  submitAddToCart();
  showPop.value = false;

};
const submitAddToCart = async () => {
  addedToCart.value = true;
  addToCart([upsaleAddObject.value]);
  // addToCartAnimation();
  // const data = await GqlAddToCart({
  //   input:  upsaleAddObject.value.data,
  // });
  // cart.value = data.addToCart?.cart;
  // await syncCartFromServer(cart.value);
}

if (_IsArray(productVariations.value) && productVariations.value.length) {
  selectedColor.value = {
    label: productVariations.value[0].name,
    value: productVariations.value[0].nameEn,
  };
}

const keychainUpdate = (data) => {
  console.log(data);
  sideA.value = data.type;
  sideAText.value = data.text;
  sideB.value = data.typeB;
  sideBText.value = data.sideBText;
  hasSideB.value = data.sideB;
  priceToAdd.value = data.priceAdd;

};
</script>
<style lang="scss">
.product-style {
  width: 100% !important;

  .product-inner {
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(189, 204, 212, 0.3);
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 20px;
    margin-top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .accessory-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .woocommerce-loop-product__title {
    font-size: 19px;
    min-height: 44px;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      font-size: 16px;

    }
  }
}
.product-upsale__item-new {
  margin: 0;
  .upsale-title {
    font-size: 18px;
    min-height: 75px;
  }
  img{
    max-width: 100%;
  }
  .price {
    min-width: 100px;
    display: inline-block;
    text-align: right;
    @media screen and (max-width: 800px) {
      min-width: 60px;
    }
  }
}

.flying-img {
  position: fixed;
  animation: fly_to_cart 1s ease-in-out;
}

@keyframes fly_to_cart {
  0% {
    left: 0;
    top: 0;
  }
  45%, 50% {
    left: var(--left);
    top: 60px;
  }
  80%, 90% {
    left: var(--left);
    top: var(--top);
    transform: scale(.2);
  }
  100% {
    left: calc(var(--left) + 40px);
    top: var(--top);
    transform: scale(.2);
  }
}
span.out-of-stock {
  color: #fff;
  min-width: 150px;
  background: var(--red);
  display: inline-block;
  padding: 5px 30px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}
//.side-text{
//
//  position: absolute;
//  right: 50%;
//  top: 50%;
//  font-size: 80px;
//  font-weight: 900;
//  transform: translate(58%, -50%);
//  letter-spacing: 4px;
//  line-height: 1;
//  padding: 0 10px;
//  white-space: nowrap;
//}
.selected-keychain-image{
  img{
    width: 100%;
  }
}
.added-icon{
  position: absolute;
  left: 0;
  z-index: 20;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &-check{

    border-radius: 50%;
    max-width: 100px;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      max-width: 80px;
    }
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>